/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Icon, Image } from '..';
import { IconSprite } from '../Icon/Icon';
import readAWSS3File from '../../utils/readAWSS3File';

type AlertProps = {
  show: boolean;
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  message: string;
  onClose: () => void;
  onClick?: () => void;
  className?: string;
};

function Alert({ show, type, message, className, onClose, onClick }: AlertProps) {
  const alertRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (show) {
      alertRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [show]);

  const handleOnClose = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      ref={alertRef}
      onClick={onClick}
      role="presentation"
      className={`absolute top-0 font-normal flex items-center 
      justify-center text-sm space-x-4 right-0 left-0 text-center transition-all 
      ease-in-out
      ${className || ''}
      ${onClick ? 'cursor-pointer' : 'cursor-default'}
      ${show ? 'min-h-[50px] p-[5px]' : 'h-0'}
      ${
        type === 'SUCCESS'
          ? 'bg-[#72c16d] text-white'
          : type === 'WARNING'
          ? 'bg-river-deep-orange text-white'
          : type === 'INFO'
          ? 'bg-[#FFE0AF] text-river-black'
          : 'bg-river-red text-white'
      }`}
    >
      {show && (
        <>
          <span>{message}</span>
          <div role="presentation" onClick={handleOnClose} className="p-[10px]">
            <Icon id="close" className="w-[12px] h-[12px] cursor-pointer" />
          </div>
        </>
      )}
    </div>
  );
}

export function IncomingCallAlert({
  show,
  caller,
  onAcceptCall,
}: {
  show: boolean;
  caller: { name: string; photo: string };
  onAcceptCall: () => void;
}) {
  const [signedProviderPhoto, setSignedProviderPhoto] = React.useState();

  React.useEffect(() => {
    if (!caller.photo) return;

    (async function readProviderPhoto() {
      const signedPhoto = await readAWSS3File('avatar', caller.photo);
      setSignedProviderPhoto(signedPhoto);
    })();
  }, [caller.photo]);

  return (
    <div
      className={`bg-river-black fixed pl-3 pr-4 py-3 top-24 rounded-xl shadow-md space-x-4 min-w-[400px] 
      text-white flex flex-row items-center justify-between text-[15px] transition-all ease-in-out ${
        show ? 'right-16 z-50' : 'right-[-150%]'
      }`}
    >
      <div
        className="h-fit w-fit bg-white rounded-full inline-flex  
        justify-center items-center shrink-0 font-medium"
      >
        <Image
          className="w-10 h-10 rounded-full"
          src={signedProviderPhoto || 'image_river_provider_avatar_placeholder.svg'}
          alt="provider avatar"
        />
      </div>
      <div className="flex flex-row flex-1 space-x-4 items-center">
        <div className="leading-normal">
          <span className="font-medium block pending-alert after:!leading-none after:!tracking-widest after:!text-xs">
            {caller.name} Calling{' '}
          </span>
          <span className="font-light text-[13px]">Incoming video call request</span>
        </div>
        <span className="h-auto w-[1px] block bg-[#E7E7E74D]/30 self-stretch" />
        <button
          onClick={onAcceptCall}
          type="button"
          className="text-white bg-[#72C16D] rounded-full font-light text-[13px] inline-flex 
          justify-center items-center pl-2 pr-3 shrink-0"
        >
          <IconSprite id="icon-incoming-call" className="w-[15px] h-[15px] mr-[5px]" />
          Accept
        </button>
      </div>
    </div>
  );
}

export function VisitAlert({
  type,
  onClick,
  visit,
}: {
  onClick: (closeIt?: boolean) => void;
  visit: string;
  type:
    | 'COMPLETED-VISIT'
    | 'PENDING-VISIT'
    | 'MEDICAL-INFO-REQUIRED'
    | 'QUESTION-ASKED'
    | 'NEW-LAB-REPORT';
}) {
  return type === 'COMPLETED-VISIT' && visit ? (
    <span
      className="bg-[#EEFBFF] border border-river-blue flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span
          className="rounded-full bg-white border border-river-blue/30 w-8 h-8 tablet:flex flex-row justify-center 
          items-center shrink-0 mr-4 hidden"
        >
          <IconSprite id="icon-emergency-heart" className="h-4 w-4 text-river-blue" />
        </span>
        <p className="text-[15px] leading-normal">
          A treatment plan is ready for your{' '}
          <strong className="underline underline-offset-1 uppercase">{visit}</strong>
        </p>
      </div>
      <button
        onClick={() => onClick(true)}
        className="text-river-blue font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Review Here
      </button>
    </span>
  ) : type === 'PENDING-VISIT' && visit ? (
    <span
      className="border border-river-deep-orange/60 bg-[#FEEDE2] flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span
          className="rounded-full bg-white border border-river-deep-orange/30 w-8 h-8 
          tablet:flex flex-row justify-center items-center shrink-0 mr-4 hidden"
        >
          <IconSprite id="icon-emergency-heart" className="h-4 w-4 text-river-deep-orange" />
        </span>
        <p className="text-[15px] leading-normal">
          Your <strong className="underline underline-offset-1 uppercase">{visit}</strong> is under
          review.
        </p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-river-deep-orange font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Click Here
      </button>
    </span>
  ) : type === 'QUESTION-ASKED' ? (
    <span
      className="bg-[#FFEDD4] border border-[#F6914F]/50 flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span
          className="rounded-full bg-white border border-[#F6914F]/50 w-8 h-8 tablet:flex flex-row justify-center 
          items-center shrink-0 mr-4 hidden"
        >
          <IconSprite id="icon-emergency-heart" className="h-4 w-4 text-[#F6914F]/50" />
        </span>
        <p className="text-[15px] leading-normal">
          You have a <strong className="underline underline-offset-1">Question</strong> from your
          Primary Care Provider.
        </p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-river-black font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Click Here
      </button>
    </span>
  ) : type === 'MEDICAL-INFO-REQUIRED' ? (
    <span
      className="bg-[#EEFBFF] border border-river-blue flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
    pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span
          className="rounded-full bg-white border border-river-blue/30 w-8 h-8 
          tablet:flex flex-row justify-center items-center shrink-0 mr-4 hidden"
        >
          <Icon id="iconHintInfo" className="h-4 w-4 text-river-blue" />
        </span>
        <p className="text-[15px] leading-normal">Update your medical information.</p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-river-blue font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Click Here
      </button>
    </span>
  ) : type === 'NEW-LAB-REPORT' ? (
    <span
      className="bg-[#EEFBFF] border border-river-blue flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span
          className="rounded-full bg-white border border-river-blue/30 w-8 h-8 tablet:flex flex-row justify-center 
          items-center shrink-0 mr-4 hidden"
        >
          <IconSprite id="icon-emergency-heart" className="h-4 w-4 text-river-blue" />
        </span>
        <p className="text-[15px] leading-normal">Your Lab results are ready</p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-river-blue font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Review Here
      </button>
    </span>
  ) : null;
}

Alert.defaultProps = {
  className: '',
  onClick: undefined,
};

export default Alert;
